<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="pl-1 primary--text">{{
          tipoInteresId != null ? formEditTitle : formNewTitle
        }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            v-model="isFormValid"
            ref="crud-form"
            id="crud-form"
            @submit.prevent="saveEdit()"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="porcentajeInteres"
                  label="Porcentaje de interés (%)"
                  item-text="porcentajeInteres"
                  item-value="id"
                  dense
                  outlined
                  autocomplete="off"
                  onkeydown="return (event.keyCode !== 69 && 
                        event.keyCode !== 109 && 
                        event.keyCode !== 107 &&
                        event.keyCode !== 188 &&
                        event.keyCode !== 190)"
                  type="number"
                  :rules="rules.required.concat(rules.decimal)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="tipoSelected"
                  label="Tipo interés"
                  item-text="name"
                  item-value="id"
                  v-model="tipo"
                  outlined
                  :rules="rules.required"
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menuDesde"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateDesdeTextfield"
                      label="Vigencia desde"
                      :append-icon="calendarIcon"
                      hint="Formato DD/MM/AAAA"
                      @blur="
                        dateDesde = parseDateToIso(dateDesdeTextfield)
                      "
                      clearable
                      dense
                      outlined
                      autocomplete="off"
                      :rules="rules.required"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateDesde"
                    no-title
                    scrollable
                    @change="dateDesdeTextfield = formatDate(dateDesde)"
                    @input="menuDesde = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="menu2"
                  v-model="menuHasta"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateHastaTextfield"
                      label="Vigencia hasta"
                      :append-icon="calendarIcon"
                      hint="Formato DD/MM/AAAA"
                      @blur="
                        dateHasta = parseDateToIso(dateHastaTextfield)
                      "
                      clearable
                      dense
                      outlined
                      autocomplete="off"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateHasta"
                    @change="dateHastaTextfield = formatDate(dateHasta)"
                    no-title
                    scrollable
                    @input="menuHasta = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="crud-form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";

export default {
  props: ["tipoInteresId"],
  data() {
    return {
      rules: rules,
      calendarIcon: enums.icons.CALENDAR,
      isFormValid: false,
      menuDesde: null,
      menuHasta: null,
      porcentajeInteres: null,
      tipo:null,
      dateHasta: null,
      dateDesde:null,
      dateDesdeTextfield: null,
      dateHastaTextfield: null,
      formNewTitle: enums.titles.NUEVO_TIPO_INTERES,
      formEditTitle: enums.titles.EDITAR_TIPO_INTERES,
      tipoSelected: [
        {
          id: 1,
          name: "Diario"
        },
        {
          id: 2,
          name: "Mensual"
        }
      ]
    };
  },
  created() {
    if (this.tipoInteresId != null) {
      this.setTipo(this.tipoInteresId);
    }
  },
  methods: {
    ...mapActions({
      getTiposInteresDeudaEmpresaById:
        "configuracion/getTiposInteresDeudaEmpresaById",
      postTiposInteresDeudaEmpresa:
        "configuracion/postTiposInteresDeudaEmpresa",
      deleteTiposInteresDeudaEmpresa:
        "configuracion/deleteTiposInteresDeudaEmpresa",
      setAlert: "user/setAlert"
    }),
    async setTipo() {
      const res = await this.getTiposInteresDeudaEmpresaById(
        this.tipoInteresId
      );
      this.porcentajeInteres = res.porcentajeInteres;
      this.tipo = res.tipo;
      this.dateDesdeTextfield = res.vigenciaDesde;
      this.dateDesde = this.parseDateToIso(this.dateDesdeTextfield);
      this.dateHastaTextfield = res.vigenciaHasta;
      this.dateHasta = this.parseDateToIso(this.dateHastaTextfield);
    },
    async saveEdit() {
      const data = {
        tipoInteresId: this.tipoInteresId,
        porcentajeInteres: this.porcentajeInteres,
        tipo: this.tipo,
        vigenciaDesde: this.dateDesde,
        vigenciaHasta: this.dateHasta
      };
      const res = await this.postTiposInteresDeudaEmpresa(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      }
      this.closeModal();
    },
    closeModal() {
      this.editTipoDialog = false;
      this.$emit("closeAndReload");
    },
    // metodos date picker
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  }
};
</script>

<style></style>
