<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tipos"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModal()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.porcentajeInteres`]="{ item }">
              <span>{{ addDecimals(item.porcentajeInteres) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item.tipoInteresId)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteEstado(item.tipoInteresId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="editTipoDialog"
      v-model="editTipoDialog"
      max-width="500px"
      @keydown.esc="closeModal"
      persistent
    >
      <editar-tipo
        :tipoInteresId="tipoInteresId"
        @closeAndReload="closeAndReload"
      />
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditarTipo from "@/components/modules/cuotas/configuracion/EditarTipo";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "TiposInteresDeudaEmpresa",
  components: { PageHeader, DeleteDialog, EditarTipo, GoBackBtn, Ayuda },

  data() {
    return {
      search: "",
      routeToGo: "FiscalizacionDeAportes",
      searchIcon: enums.icons.SEARCH,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      showDeleteModal: false,
      idToDelete: null,
      editTipoDialog: false,
      title: enums.titles.TIPOS_INTERES,
      optionCode: enums.webSiteOptions.TIPOS_INTERES_DEUDA_EMPRESA,
      showHelp: false,
      showExpand: false,
      showIcon: true,
      titleDelete: "¿Desea eliminarlo?",
      tipos: [],
      headers: [
        {
          text: "Tipo de interés",
          value: "tipoNombre",
          align: "start",
          width: "15%"
        },
        {
          text: "Porcentaje",
          value: "porcentajeInteres",
          align: "end"
        },
        {
          text: "Vigencia desde",
          value: "vigenciaDesde",
          align: "end"
        },
        {
          text: "Vigencia hasta",
          value: "vigenciaHasta",
          align: "end"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "end"
        }
      ],
      allowedActions: null,
      canEdit: false,
      canDelete: false,
      canCreate: false
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadTipos();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getTiposInteresDeudaEmpresa: "configuracion/getTiposInteresDeudaEmpresa",
      deleteTiposInteresDeudaEmpresa:
        "configuracion/deleteTiposInteresDeudaEmpresa",
      setAlert: "user/setAlert"
    }),
    async loadTipos() {
      this.tipos = await this.getTiposInteresDeudaEmpresa();
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_TIPO_INTERES:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_TIPO_INTERES:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_TIPO_INTERES:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async confirmDelete() {
      const response = await this.deleteTiposInteresDeudaEmpresa({
        tipoInteresId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.snackbarMsg = "Eliminado con éxito.";
        this.snackbar = true;
        this.loadTipos();
      }
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    deleteEstado(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    openModal(tipoInteresId) {
      this.editTipoDialog = true;
      this.tipoInteresId = tipoInteresId;
    },
    closeModal() {
      this.editTipoDialog = false;
    },
    closeAndReload() {
      this.editTipoDialog = false;
      this.loadTipos();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
